<template>
  <div class="container">
    <h1>FAQ</h1>
    <p>Starting with Visicardia</p>
    <br />
    <h3>How do I login to Visicardia?</h3>
    <p>Visicardia provides multiple login options, namely:</p>
    <ul>
      <li>Google</li>
      <li>Facebook</li>
      <li>E-Mail Address</li>
      <li>Phone Numbers</li>
    </ul>
    <h3>How Secure is Login to Visicardia?</h3>
    <p>
      We take Security and your Privacy very seriously. Visicardia uses the latest
      methodologies in order to ensure that the we adhere to the highest norms
      of latest security standards. Cards
    </p>
    <h3>Is there a limit to creating a card?</h3>
    <p>
      No, currently there are no limits to creating cards. A user can create
      unlimited number of cards.
    </p>
    <h3>Can I share a card to non Visicardia contact?</h3>
    <p>
      Yes, you can share your card to a non Visicardia contact from your contact
      list. This can be done with either mobile no/ email address. However, if
      the user does not have the Visicardia app a SMS/email will be sent to the
      contact to download Visicardia. Once the contact downloads the Visicardia App the
      card can be seen by the contact who can then accept or reject the card.
      <br />One can also use the “QR Code” to transfer a card to a user without
      using the mobile number or e-mail address.
    </p>
    <h3>Does my contact see the changes made on my card immediately?</h3>
    <p>
      Yes, the update on the card reflects to the shared contact cards
      immediately.
    </p>
    <h3>What happens if I delete my card?</h3>
    <p>
      When you delete your card, the card gets deleted from all the contacts you
      have shared the card with. However, one needs to proceed with caution as
      the deleted card cannot be retrieved.
    </p>
    <h3>
      What if I want to delete the shared card send to a particular contact?
    </h3>
    <p>
      You can delete your shared card send to a particular contact. In the
      shared cards tab select the contact and click the delete button on the
      card to remove it from the contact list of that particular contact.
    </p>
    <h3>I really like the pending card logic in Visicardia.</h3>
    <p>
      Thanks! This logic was implemented in the interest of the Visicardia user to
      accept only the cards that he/she is interested in rather than just
      dumping all the cards that are shared.
    </p>
    <h3>
      What if I want to introduce two of my contacts who have shared cards with
      me?
    </h3>
    <p>
      We have got you covered!! You can introduce contacts that have shared
      cards with you. Using the “Make an Intro” Tab, a user can introduce
      contacts with each other. The users who have been introduced, will have
      the ability to share cards with each other. Also, the cards thus shared
      will have the name of the introducer mentioned.
    </p>
    <h3>What does swap in the shared card means?</h3>
    <p>
      If you want to swap the existing shared card to the contact with another
      card from your “My Cards” list, Click on the swap button and you will see
      all your cards. Tap on the card you wish to swap with the existing shared
      card.
    </p>
    <h3>Can I share the cards I received to another Visicardia contact?</h3>
    <p>
      Yes, you can share received card to another Visicardia contact by clicking the
      forward button in “Received Cards” tab. However, it will not be seen to
      the shared contact unless permitted by the owner of the card.
    </p>
    <h3>
      I have a physical card well designed how can I use this card in Visicardia?
    </h3>
    <p>
      You can very well get the feel of you physical card in Visicardia. It is very
      simple, just click a picture of your physical card. Now, create a blank
      card in Visicardia and in the design tab click the card background and select
      the physical card image from the phone gallery. There you go…. Your
      physical card is now digital in Visicardia.
    </p>
    <h3>What does note in the pending card means?</h3>
    <p>
      Note with the pending card means you can add a note referring to the card
      you are about to accept for example:- You met a person in a Tech
      Exhibition and you exchange card through Visicardia. Now before accepting the
      card you can add a note stating “met him in Tech Exhibition in Mumbai”,
      this note is also searchable so if you don’t remember the contact name and
      you can search by saying “Tech Exhibition” and the card will be filtered
      and presented to you.
    </p>
    <h3>What is the advantage of Public card?</h3>
    <p>
      To make your presence known in the nearby vicinity and attract customers
      you can create a public card and add searchable business tags (max 10).
      Any Visicardia app user can search based on tags and see the card as per
      requirement.
    </p>
    <h3>Why I am unable to save my public card?</h3>
    <p>
      It is possible that you have not entered at least one phone number and
      address. In order to make a card public, you need to ensure that at least
      one phone number and one address is entered. This enables the user to geo
      locate the card and will aid the user in reaching the business address
      with ease.
    </p>

    <h3>How does QR Code work in the Visicardia?</h3>
    <p>
      Each card created by you is associated with unique “QR Code”. Below are
      some of the benefits:
    </p>
    <ul>
      <li>
        If you have an establishment (retail shop, clinic, restaurants, hotel)
        you can avoid printing physical cards. Just take a print out of “QR
        Code” of your card and place it near a visible area. The customers with
        the Visicardia app can simply scan the “QR Code” and add the card into their
        account. Such Cards will be visible in the “Received Tab”.
      </li>
      <li>
        When sharing a card between the two Visicardia apps, a user one can just
        display the “QR Code” and accept the card and vice versa. This makes
        sharing of cards a breeze!!
      </li>
    </ul>

    <h3>What is the use of Status in Visicardia?</h3>
    <p>
      That’s a good question! Our aim is not make Visicardia a social networking
      platform but to cover all the aspects of business networking. Considering
      Visicardia is applicable to different types of establishments, each
      establishment may want to make their network contacts aware of any events
      that are happening in their establishment. The Status Tab enables a user
      to broadcast and provide such information to all users with whom the cards
      have been shared with. I see start date and end date when uploading images
      what that means? Using this feature one can schedule the status for a
      future date. For example, when a doctor is away from the clinic he/she can
      schedule the status in order to make his patients aware of his/her
      availability for the said time period. Chat
    </p>
    <h3>Why do I not see all the received contacts in the chat room?</h3>
    <p>
      The reason could be multi-fold. You need to check if the contact’s shared
      card has chat enabled and if it is so and you still don’t see then please
      check if the card you shared to this user has chat enabled too. In order
      to prevent unnecessary deluge of chat messages, a chat is allowed only
      when there is consent by both parties.
    </p>
    <h3>
      Can all the contacts in the chat room see me online if I enter the chat
      room?
    </h3>
    <p>
      No the beauty is when you open chat room you are not shown as online to
      your contacts. You are only seen online to the user who you start
      conversations with. This way you can skip from one user to another user
      with anyone knowing you are in the chat room
    </p>
    <h3>Is my chat encrypted?</h3>
    <p>Yes your chat are encrypted end to end.</p>
    <h3>Can I lock the chat room?</h3>
    <p>
      Yes you can lock chat room. Chat lock is available user-wise so it is not
      locking of the whole chat room but you can lock individual chat rooms with
      different passwords. However, the only caveat here is if you forgot the
      password you can reset the password but by doing so you may lose all the
      previous chats with the contact. This feature is implemented keeping in
      mind privacy and security.
    </p>
  </div>
</template>
<script>
export default {
  name: 'Help',
  created(){
    document.title = "Visicardia :: Help";
  },
  beforeDetroy() {
    document.title = "Visicardia";
  },
}
</script>